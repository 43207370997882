import { Injectable, Injector } from '@angular/core';
import { BitfStrapiGraphQlAuthService } from '@common/libs/bitforce/core/services/auth/strapi-auth/bitf-strapi-graph-ql-auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends BitfStrapiGraphQlAuthService {
  constructor(protected injector: Injector) {
    super(injector);
  }
}
