<div class="h-100 d-flex overflow-hidden">
  <div class="col-15 px-0"
    [ngClass]="{'col-24': !showFilters}">
    <mpa-bulk-actions-toolbar [items]="items"
      [archivesValue]="archivesValue"
      [archivesEstimate]="archivesEstimate"
      [archivesQuantity]="archivesQuantity"
      [archivesNPieces]="archivesNPieces"
      [apiCallStateName]="apiCallStateName"
      [(allItemsSelected)]="allItemsSelected"
      [selectedItems]="selectedItems"
      [unselectedItems]="unselectedItems"
      [pagination]="pagination"
      [isReadOnly]="isReadOnly"
      [(folder)]="folder"
      (archivesRemoved)="onArchivesRemoved($event)"
      (invertSelection)="onInvertSelection()">
    </mpa-bulk-actions-toolbar>

    <cdk-virtual-scroll-viewport #infiniteScrollElementRef
      [itemSize]="cardHeight"
      [minBufferPx]="cardHeight * bufferRowNumber"
      [maxBufferPx]="cardHeight * bufferRowNumber"
      [templateCacheSize]="templateCacheSize"
      class="item-gallery">
      <div class="d-flex px-2"
        *cdkVirtualFor="let itemGroup of groupedItems; let groupIndex = index; ">
        <mpa-item-card class="col-24 col-md-8 col-lg-6 p-2"
          *ngFor="let item of itemGroup; let groupItemIndex = index; trackBy: trackByFn;"
          [isReadOnly]="isReadOnly"
          (select)="onToggleItemSelection(groupIndex * chunkSize + groupItemIndex)"
          (detail)="onOpenItemDetailsDialog(groupIndex * chunkSize + groupItemIndex, $any($event))"
          (delete)="onDeleteItem(groupIndex * chunkSize + groupItemIndex, $any($event))"
          [isSelected]="isItemSelected(item)"
          [folder]="folder"
          [item]="item">
        </mpa-item-card>
      </div>

      <div class="w-100">
        <bitf-mat-infinite-scroll-trigger *ngIf="groupedItems?.length && infiniteScrollOptions"
          [isLoading]="isLoading"
          [options]="infiniteScrollOptions"
          [pagination]="pagination"
          [apiCallStateName]="apiCallStateName"></bitf-mat-infinite-scroll-trigger>
      </div>
    </cdk-virtual-scroll-viewport>
  </div>

  <div class="col-9 px-0"
    [ngClass]="{'d-none': !showFilters}">
    <div class="filter-panel-container">
      <mpa-filter-panel [apiCallStateName]="apiCallStateName"
        [folder]="folder"></mpa-filter-panel>
    </div>
  </div>
</div>