import { NgModule, ErrorHandler } from '@angular/core';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { environment } from '@env/environment';

import { BrowserModule } from '@angular/platform-browser';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { BitfOAuthInterceptor } from '@bitf/interceptors/bitf-oauth-interceptor.service';
import { BitfRetryInterceptor } from '@bitf/interceptors/bitf-retry-interceptor.service';
import { BitfApiUiMessagesInterceptor } from '@bitf/interceptors/bitf-api-ui-messages-interceptor.service';
import { BitfLanguageInterceptor } from '@bitf/interceptors/bitf-language-interceptor.service';
import { BitfApiErrorsInterceptor } from '@bitf/interceptors/bitf-api-errors-interceptor.service';
// eslint-disable-next-line max-len
import { BitfApiEnvelopeMapperInterceptor } from '@bitf/interceptors/bitf-api-envelope-mapper-interceptor.service';

import { BitfErrorHandlerService } from '@bitf/services/error-handler/bitf-error-handler.service';
import { BitfTranslateLoader } from '@bitf/loaders';

import { ApolloModule, APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { InMemoryCache } from '@apollo/client/core';
// @ts-ignore
import extractFiles from 'extract-files/extractFiles.mjs';
// @ts-ignore
import isExtractableFile from 'extract-files/isExtractableFile.mjs';

import { APP_VERSION } from '@env/version';

const EXPORTS_COMPONENTS = [];
const COMPONENTS = [];

export function HttpLoaderFactory(http: HttpClient) {
  return new BitfTranslateLoader(http, './assets/i18n/', `.json?v=${APP_VERSION}`);
}
@NgModule({
  imports: [
    ApolloModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BitfOAuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BitfLanguageInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BitfApiErrorsInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BitfApiUiMessagesInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BitfApiEnvelopeMapperInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BitfRetryInterceptor,
      multi: true,
    },
    { provide: ErrorHandler, useClass: BitfErrorHandlerService },
    {
      // AUTH: https://apollo-angular.com/docs/1.0/basics/network-layer/#middleware
      provide: APOLLO_OPTIONS,
      useFactory: (httpLink: HttpLink) => {
        const http = httpLink.create({
          uri: environment.graphQlUrl,
          includeExtensions: true,
          extractFiles: body => extractFiles(body, isExtractableFile),
        });

        // const middleware = new ApolloLink((operation, forward) => {
        //   // operation.setContext({
        //   //   headers: new HttpHeaders().set(
        //   //     'Authorization',
        //   //     `Bearer ${localStorage.getItem('token') || null}`
        //   //   ),
        //   // });
        //   forward(operation).map(response => {
        //     debugger;
        //     console.log(response);
        //   });
        //   return forward(operation);
        // });

        // const link = middleware.concat((http as any) as ApolloLink);

        return {
          cache: new InMemoryCache(),
          link: http,
          defaultOptions: {
            watchQuery: {
              fetchPolicy: 'no-cache',
              errorPolicy: 'all',
            },
            query: {
              fetchPolicy: 'no-cache',
              errorPolicy: 'all',
            },
          },
        };
      },
      deps: [HttpLink],
    },
  ],
  declarations: [...EXPORTS_COMPONENTS, ...COMPONENTS],
  exports: [...EXPORTS_COMPONENTS],
})
export class CommonCoreModule {}
