import { AfterViewInit, Component, HostListener, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { Observable, Subscription } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

import { chunkArray } from '@bitf/utils/bitf-array.utils';
import { BitfApiRequestPart } from '@common/libs/bitforce/core/api-call-state/bitf-api-request-part';

import { ViewUpdateItemDialogComponent } from '@web/shared/view-update-item-dialog/view-update-item-dialog.component';
import { Archive, Folder, Store } from '@common/core/models';
import { IBitfApiPagination, IBitfGraphQlResponse } from '@common/interfaces';
import {
  ApiCallStateService,
  ArchivesService,
  StoreService,
  DialogsService,
  UiRoleManagerService,
} from '@services';
import { EApiCallStateNames, EApiRequestPartKeys, ERoleActions, eStoreActions } from '@web/enums';
import { FoldersService } from '@common/core/services/graph-ql/folders.service';
import { CONSTANTS } from '@web/constants';
@Component({
  selector: 'mpa-items-gallery',
  templateUrl: './items-gallery.component.html',
  styleUrls: ['./items-gallery.component.scss'],
})
export class ItemsGalleryComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() apiCallStateName: EApiCallStateNames;
  @Input() showFilters = true;
  @Input() isReadOnly = false;
  @Input() folder?: Folder;

  pagination: IBitfApiPagination;
  eApiRequestPartKeys = EApiRequestPartKeys;
  eRoleActions = ERoleActions;
  isLoading = false;
  infiniteScrollOptions: IntersectionObserverInit;
  groupedItems: Array<Archive[]>;
  selectedItems = new Map<number, Archive>();
  unselectedItems = new Map<number, Archive>();
  allItemsSelected = false;
  isMultiSelecting = false;
  multiSelectStartIndex = -1;
  chunkSize: number;
  bufferRowNumber = 4;
  templateCacheSize = 0;
  archivesValue: number;
  archivesEstimate: number;
  archivesQuantity: number;
  archivesNPieces: number;
  public cardHeight: number;
  // NOTE: number of card needed to fill the viewport + 1 row
  infiniteScrollLoadExtraCard = 6;

  private paginationRequestPart: BitfApiRequestPart;
  private requestPart: BitfApiRequestPart;
  private subscriptions = new Subscription();
  private lastCallSubscription = new Subscription();

  get items() {
    return this.storeService.store.galleryItems;
  }

  @ViewChild('infiniteScrollElementRef', { static: true }) el: CdkVirtualScrollViewport;

  constructor(
    private dialogsService: DialogsService,
    private archivesService: ArchivesService,
    private foldersService: FoldersService,
    private apiCallStateService: ApiCallStateService,
    private storeService: StoreService,
    uiRoleManagerService: UiRoleManagerService
  ) {
    if (uiRoleManagerService.canI(ERoleActions.VIEW_VALUE)) {
      this.cardHeight = 332 + 8 + 8;
    } else {
      this.cardHeight = 302 + 8 + 8;
    }
  }

  ngOnInit(): void {
    this.initApiCallState();
    this.initItemsGroupSize();
    this.chunkSize = this.getChunkSize();
  }

  ngAfterViewInit() {
    this.infiniteScrollOptions = {
      root: this.el.elementRef.nativeElement,
      rootMargin: `${this.cardHeight * (this.bufferRowNumber - 1)}px`,
    };
  }

  @HostListener('window:keydown', ['$event'])
  @HostListener('window:keyup', ['$event'])
  keyDown(event: KeyboardEvent) {
    if (/(Mac|iPhone|iPod|iPad)/i.test(navigator['userAgent'])) {
      if (event.key === 'Meta') {
        this.isMultiSelecting = event.type === 'keydown';
        if (event.type === 'keyup') {
          this.multiSelectStartIndex = -1;
        }
      }
    } else {
      if (event.key === 'Control') {
        this.isMultiSelecting = event.type === 'keydown';
        if (event.type === 'keyup') {
          this.multiSelectStartIndex = -1;
        }
      }
    }
  }

  onOpenItemDetailsDialog(index: number, isZoom = false): void {
    const dialog = this.dialogsService.dialog.open(ViewUpdateItemDialogComponent, {
      ...CONSTANTS.FULL_SCREEN_DIALOG_SIZE,
      data: {
        isZoom,
        isReadOnly: this.isReadOnly,
        selectedItemIndex: index,
        apiCallStateName: this.apiCallStateName,
        totalItems: this.pagination.totalItems,
      },
    });
  }

  onDeleteItem(
    index: number,
    deleteType: 'deleteItem' | 'deleteFromFolder' | 'deleteFromLoan' = 'deleteItem'
  ): void {
    const itemToRemoveFromFolder = this.items[index];

    switch (deleteType) {
      case 'deleteItem':
        if (this.folder) {
          this.folder.archives = this.folder.archives.filter(
            archive => archive.id !== itemToRemoveFromFolder.id
          );
          this.foldersService
            .updateItem({ body: this.folder })
            .pipe(
              switchMap((res: IBitfGraphQlResponse<Folder>) => {
                this.folder = res.content;
                return this.archivesService.deleteItem({ id: this.items[index].id });
              })
            )
            .subscribe(_ => {
              this.updateOnDeleteItem(index);
            });
        } else {
          this.archivesService.deleteItem({ id: this.items[index].id }).subscribe(() => {
            this.updateOnDeleteItem(index);
          });
        }
        break;

      case 'deleteFromFolder':
        this.folder.archives = this.folder.archives.filter(
          archive => archive.id !== itemToRemoveFromFolder.id
        );
        this.foldersService
          .updateItem({ body: this.folder })
          .subscribe((response: IBitfGraphQlResponse<Folder>) => {
            this.folder = response.content;
            this.updateOnDeleteItem(index);
          });
        break;
      case 'deleteFromLoan':
        break;
      default:
        break;
    }
  }

  private updateOnDeleteItem(index: number): void {
    const itemToRemoveFromFolder = this.items[index];
    this.selectedItems.delete(itemToRemoveFromFolder.id);
    this.unselectedItems.delete(itemToRemoveFromFolder.id);
    this.getRemovedItems(this.items.length, 1)
      .pipe(
        tap(response => {
          this.storeService.setStore(() => {
            this.items.splice(index, 1);
            this.items.push(...response.content);
          }, eStoreActions.SET_GALLERY_ITEMS);
          this.pagination.totalItems = response.pagination.totalItems;
        })
      )
      .subscribe();

    this.storeService.setStore(() => {}, eStoreActions.EVENT_UPDATE_ARCHIVES_TOTAL_VALUE);
  }

  onToggleItemSelection(index: number): void {
    if (this.isMultiSelecting) {
      if (this.multiSelectStartIndex === -1) {
        this.toggleItemSelection(index);
      } else {
        const endIndex = index > this.multiSelectStartIndex ? index + 1 : this.multiSelectStartIndex;
        this.multiSelectStartIndex =
          index > this.multiSelectStartIndex ? this.multiSelectStartIndex + 1 : index;
        for (let i = this.multiSelectStartIndex; i < endIndex; i++) {
          this.toggleItemSelection(i);
        }
      }
      this.multiSelectStartIndex = index;
    } else {
      this.toggleItemSelection(index);
    }
  }

  // NOTE this is called by the bulk actions, remove or move from folder
  onArchivesRemoved(removedItems: Archive[]) {
    this.resetSelection();
    const removedItemsIds = removedItems.map(item => item.id);
    this.folder.archives = this.folder.archives.filter(archive => removedItemsIds.indexOf(archive.id) === -1);

    this.getRemovedItems(this.items.length, removedItems.length)
      .pipe(
        tap((response: IBitfGraphQlResponse<Archive[]>) => {
          this.storeService.setStore(() => {
            // NOTE: we remove the deleted or moved items from the list
            removedItems.forEach(item => {
              const index = this.items.findIndex(i => i.id === item.id);
              this.items.splice(index, 1);
            });
            // NOTE: We concatenate at the end of the list the missing items to fill the gap
            // of the deleted or moved items
            this.items.push(...response.content);
          }, eStoreActions.SET_GALLERY_ITEMS);
          this.pagination.totalItems = response.pagination.totalItems;
        })
      )
      .subscribe();
  }

  private getRemovedItems(
    listLength: number,
    numberOfRemovedItems: number
  ): Observable<IBitfGraphQlResponse<Archive[]>> {
    const requestParams = this.apiCallStateService.getApiRequest(this.apiCallStateName);
    return this.archivesService.getItems({
      ...requestParams,
      start: listLength - numberOfRemovedItems,
      limit: numberOfRemovedItems,
    });
  }

  trackByFn(item) {
    return item.id;
  }

  // START SELECTION MANAGEMENT ----------------------------------------

  isItemSelected(item: Archive) {
    return (this.allItemsSelected && !this.unselectedItems.has(item.id)) || this.selectedItems.has(item.id);
  }

  onInvertSelection() {
    if (this.allItemsSelected) {
      this.selectedItems = new Map(this.unselectedItems);
      this.unselectedItems.clear();
    } else {
      this.unselectedItems = new Map(this.selectedItems);
      this.selectedItems.clear();
    }
    this.allItemsSelected = !this.allItemsSelected;
  }

  private toggleItemSelection(index: number): void {
    const toggleMapItemSelection = (mapItem, i) => {
      if (mapItem.has(this.items[i].id)) {
        mapItem.delete(this.items[i].id);
      } else {
        mapItem.set(this.items[i].id, this.items[i]);
      }
    };

    if (this.allItemsSelected) {
      toggleMapItemSelection(this.unselectedItems, index);

      if (this.pagination.totalItems === this.unselectedItems.size) {
        this.resetSelection();
      }
    } else {
      toggleMapItemSelection(this.selectedItems, index);

      if (this.pagination.totalItems === this.selectedItems.size) {
        this.selectAllItems();
      }
    }
  }

  private resetSelection(): void {
    this.allItemsSelected = false;
    this.selectedItems.clear();
    this.unselectedItems.clear();
  }

  private selectAllItems(): void {
    this.allItemsSelected = true;
    this.selectedItems.clear();
    this.unselectedItems.clear();
  }

  // END SELECTION MANAGEMENT ----------------------------------------

  private initApiCallState() {
    this.paginationRequestPart = this.apiCallStateService.getRequestPart(
      this.apiCallStateName,
      EApiRequestPartKeys.PAGINATION
    );
    this.requestPart = this.apiCallStateService.getRequestPart(
      this.apiCallStateName,
      EApiRequestPartKeys.FILTERS
    );

    // NOTE: Add the role visibility to the filters, forced to be defaultPartData to avoid overwriting
    this.requestPart.defaultPartData.formValue = {
      ...(this.requestPart.defaultPartData.formValue || {}),
      ...this.storeService.store.user.archiveRoleVisibilityObj,
    };

    // NOTE: Add hardcoded folder in the filters
    if (this.folder) {
      this.requestPart.formValue = {
        ...(this.requestPart.formValue || {}),
        folders: [this.folder],
      };
    }

    this.subscriptions.add(
      this.apiCallStateService
        .getStateStore$(this.apiCallStateName)
        .pipe(
          tap(() => {
            this.loadData();
          })
        )
        .subscribe()
    );

    // NOTE: we reset the pagination because with the infinite scroll the user
    // will start the get items from the previous page
    // this would be not a problem with the standard pagination
    // in this function
    this.paginationRequestPart.reset();
    // we have to dispatch to run build with the resetted pagination and this will call the
    // observable above
    this.apiCallStateService.setStore(() => {}, this.apiCallStateName);

    // NOTE: without the infinite scroll
    // this.loadData();

    // NOTE: Calculate and watch to update the archives total value
    this.loadArchivesValue();
    this.subscriptions.add(
      this.storeService.selectStore(eStoreActions.EVENT_UPDATE_ARCHIVES_TOTAL_VALUE).subscribe(() => {
        this.loadArchivesValue();
      })
    );
  }

  private loadArchivesValue() {
    const requestParams = this.apiCallStateService.getApiRequest(this.apiCallStateName);
    this.archivesService.getArchivesStats(requestParams).subscribe(response => {
      this.archivesValue = response.content.totalArchivesValue;
      this.archivesEstimate = response.content.totalArchivesEstimate;
      this.archivesQuantity = response.content.totalArchivesQuantity;
      this.archivesNPieces = response.content.totalArchivesNPieces;
    });
  }

  private loadData() {
    this.isLoading = true;
    const requestParams = this.apiCallStateService.getApiRequest(this.apiCallStateName);
    // console.log('requestParams', requestParams);
    this.lastCallSubscription.unsubscribe();
    this.lastCallSubscription = this.archivesService.getItems(requestParams).subscribe(response => {
      // console.log(response.content);
      this.isLoading = false;

      if (response.pagination.page === 1) {
        // If we are at the first page we reset the list
        this.resetSelection();
        this.storeService.setStore((store: Store) => {
          store.galleryItems = response.content;
        }, eStoreActions.SET_GALLERY_ITEMS);
      } else if (response.pagination.page > 1) {
        // If the page is > 0 we ve to concatenate the list to create infinite scroll effect
        this.storeService.setStore((store: Store) => {
          store.galleryItems = [...store.galleryItems, ...response.content];
        }, eStoreActions.CONCAT_GALLERY_ITEMS);
      }
      this.pagination = response.pagination;
    });
  }

  private chunkItems({ concat }: { concat: boolean } = { concat: false }) {
    if (concat) {
      // REMOVEME: non e' usato piu'
      // if (!this.items.length) {
      //   return;
      // }
      // const lastGroup = this.groupedItems[this.groupedItems.length - 1];
      // const emtpySlots = this.chunkSize - lastGroup.length;
      // if (emtpySlots) {
      //   lastGroup.push(...this.items.slice(0, emtpySlots));
      // }
      // this.groupedItems = [...this.groupedItems, ...chunkArray(this.items, this.chunkSize)];
    } else {
      if (!this.items?.length) {
        this.groupedItems = [];
        return;
      }
      this.groupedItems = chunkArray(this.items, this.chunkSize);
    }
  }

  private initItemsGroupSize() {
    this.storeService.selectStore(eStoreActions.BREAKPOINT).subscribe(event => {
      const oldScrollTop = this.el.elementRef.nativeElement.scrollTop;
      const oldChunkSize = this.chunkSize;
      this.chunkSize = this.getChunkSize();
      this.chunkItems();
      this.el.checkViewportSize();
      setTimeout(() => {
        this.el.elementRef.nativeElement.scrollTop =
          Math.floor((Math.round(oldScrollTop / this.cardHeight) * oldChunkSize) / this.chunkSize) *
          this.cardHeight;
      }, 0);
    });
    this.storeService.selectStore(eStoreActions.SET_GALLERY_ITEMS).subscribe(event => {
      this.chunkItems();
    });
    this.storeService.selectStore(eStoreActions.CONCAT_GALLERY_ITEMS).subscribe(event => {
      this.chunkItems();
    });
  }

  private getChunkSize(): number {
    const { isMedium, isLarge, isXLarge } = this.storeService.store.activeBreakpoints;
    let chunkSize = 1;

    if (isMedium) {
      chunkSize = 3;
    } else if (isLarge || isXLarge) {
      chunkSize = 4;
    }
    return chunkSize;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
