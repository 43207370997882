import { EArchiveType, EAppSections, EItemTabs, EShortAppName } from '@web/enums';

const baseApiUrl = 'http://localhost:3002/';
const apiUrl = `${baseApiUrl}`;
const graphQlUrl = 'https://be.appprosciuttibucasf.it/graphql';

export const environment = {
  production: true,
  name: 'cellar-production',
  appName: 'Cantina',
  shortAppName: EShortAppName.CEL,
  appUrl: 'https://appprosciuttibucasf.it/',
  storageVersion: '1.0.0',
  overrideI18nPath: 'cellar/',
  archiveType: EArchiveType.CELLAR,
  appSections: [EAppSections.SALE, EAppSections.FOLDER, EAppSections.UPLOAD, EAppSections.QR_SCANNER],
  itemTabs: [EItemTabs.IMAGES],
  apiUrl,
  graphQlUrl,
  authInterceptorUrls: [apiUrl, graphQlUrl],
  mockApiUrl: 'https://localhost:3002/',
  logUrl: '',
  sockets: { url: '' },
  httpRetryConfig: {
    enabled: true,
    verbs: {
      get: { enabled: true, timeout: 6000, maxRetries: 2, retryForStatusesCodes: [500] },
      post: { enabled: false, timeout: null, maxRetries: 1, retryForStatusesCodes: [500] },
      patch: { enabled: false, timeout: null, maxRetries: 1, retryForStatusesCodes: [500] },
      put: { enabled: false, timeout: null, maxRetries: 1, retryForStatusesCodes: [500] },
      delete: { enabled: false, timeout: null, maxRetries: 1, retryForStatusesCodes: [500] },
    },
  },
  graphQlConfig: {
    enabled: true,
    requestTimeout: 1800000,
  },
  loggerConfig: {
    enabled: true,
    maxLogRentention: 20,
    logHttpErrorsWithStatusesCodes: ['0', '4XX', '5XX'],
    logDecoratedExceptions: true,
    logExceptions: true,
    saveLogMessagesInterval: 30000,
    logUrl: 'https://logs.logdna.com/logs/ingest?apikey=bf8cf553dba4a1e882fa09491aaed2c7',
    sendLogs: true,
    sendRequestBody: false,
    sendQueryParams: false,
  },
  tagManager: {
    enabled: false,
    apiKey: '',
  },
  registerServiceWorker: false,
  httpPassword: 'mpatest',
};
