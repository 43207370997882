import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { TranslateLoader } from '@ngx-translate/core';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export class BitfTranslateLoader implements TranslateLoader {
  constructor(
    private http: HttpClient,
    public prefix: string = '/assets/i18n/',
    public suffix: string = '.json'
  ) {}

  /**
   * Gets custom translations if available and merges them with default translations
   */
  public getTranslation(lang: string): Observable<Object> {
    if (environment.overrideI18nPath != '') {
      return forkJoin({
        defaultPath: this.http.get(`${this.prefix}${lang}${this.suffix}`),
        customPath: this.http.get(`${this.prefix}${environment.overrideI18nPath}${lang}${this.suffix}`),
      }).pipe(map(response => this.mergeDeep(response.defaultPath, response.customPath)));
    }
    return this.http.get(`${this.prefix}${lang}${this.suffix}`);
  }

  mergeDeep(target, source) {
    for (const key of Object.keys(source)) {
      if (source[key] instanceof Object && target[key]) {
        this.mergeDeep(target[key], source[key]);
      } else {
        target[key] = source[key];
      }
    }
    return target;
  }
}
