import { Producer } from './producer.model';
import { SuperStrapiModel } from './super-strapi.model';

export class Lot extends SuperStrapiModel {
  name: string;
  producer: Producer;

  constructor(data, modelInstance?: Partial<Lot>) {
    super(data, modelInstance);

    if (modelInstance) {
      return;
    }

    if (data.attributes?.producer?.data) {
      this.producer = new Producer(data.attributes?.producer.data);
    }
  }

  get serialised() {
    return {
      id: this.id,
      name: this.name,
      producer: !!this.producer ? this.producer.serialised.id : this.producer,
    };
  }
}
